import React from 'react'
import { Scrollbar } from '@olystic/design_system_3'
import style from './RHStatsModal.module.scss'
import { Dropdown, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { useRHBase } from '../../../../hooks/useRHBase'
import { dateFilterMapping } from '../RHTable/dateFilterMapping'
import { useStatRH } from '../../../../hooks/useStatRH'
import classNames from 'classnames'

export default function RHStatsModal({ show, onHide, baseId }) {
  const { t } = useTranslation()
  const { quality } = useStatRH(baseId)

  return (
    <Modal size='xl' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='d-flex align-items-center'>
            <div
              className={`rounded-pill me-2 bg-${quality}`}
              style={{ width: '0.6875rem', height: '0.6875rem' }}
            />
            {t('component.rhStatsModal.title')}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <RHStats baseId={baseId} />
      </Modal.Body>
    </Modal>
  )
}

export function RHStats({ baseId }) {
  const { t } = useTranslation()
  const base = useRHBase(baseId)
  const { data } = useStatRH()

  async function handleChangeModality(modality, type) {
    base.updateGroup.mutate({
      ...base.base.data,
      modalities: { ...base.base.data.modalities, [type]: modality },
    })
  }

  return (
    <>
      <p className='py-4 px-8'>{t('component.rhStatsModal.description')}</p>

      <div className={style.header}>
        <p className='m-0 fw-semibold'>{t('component.rhStatsModal.header1')}</p>
        <p className='m-0 fw-semibold'>{t('component.rhStatsModal.header2')}</p>
        <p className='m-0 fw-semibold'>{t('component.rhStatsModal.header3')}</p>
      </div>
      <Scrollbar style={{ maxHeight: '55vh' }}>
        {Object.keys(data).map((category) => (
          <div key={category}>
            <div className='px-8 py-3 border-bottom bg-secondary bg-opacity-25 d-flex align-items-center justify-content-between'>
              <p className='m-0 d-flex align-items-center gap-2'>
                {t(`category.${category}`)}
                {data[category]['maxVariablesQuality'] !== 'success' && (
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip className='position-fixed'>
                        {t(
                          `component.rhStatsModal.tooltip.category.${data[category]['maxVariablesQuality']}`,
                        )}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className={`text-${data[category]['maxVariablesQuality']}`}
                    />
                  </OverlayTrigger>
                )}
              </p>
              {(category === 'ps_age' || category === 'ps_anciennete') && (
                <Form.Group
                  controlId='modality'
                  className='max-width-30 min-width-15 gap-3 d-flex align-items-center'
                >
                  <Form.Label className='m-0'>
                    {t('component.rhStatsModal.label')}
                  </Form.Label>
                  <Dropdown
                    onSelect={(eventKey) => {
                      handleChangeModality(
                        eventKey,
                        category === 'ps_age' ? 'age' : 'seniority',
                      )
                    }}
                  >
                    <Dropdown.Toggle>
                      {t(`component.rhStatsModal.modality`, {
                        count:
                          base.base.data.modalities[
                            category === 'ps_age' ? 'age' : 'seniority'
                          ].split('_')[1],
                      })}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(
                        dateFilterMapping[
                          category === 'ps_age' ? 'age' : 'seniority'
                        ],
                      ).map((group) => (
                        <Dropdown.Item key={group} eventKey={group}>
                          {t('component.rhStatsModal.modality', {
                            count: group.split('_')[1],
                          })}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              )}
            </div>
            <div>
              {Object.keys(data[category]['values'])
                .sort((a, b) => {
                  let order =
                    category === 'ps_age' || category === 'ps_anciennete'
                      ? dateFilterMapping[
                          category === 'ps_age' ? 'age' : 'seniority'
                        ][
                          base.base.data.modalities[
                            category === 'ps_age' ? 'age' : 'seniority'
                          ]
                        ].labels
                      : null

                  if (order) {
                    return order.indexOf(a) - order.indexOf(b)
                  }
                  return a.localeCompare(b)
                })
                .map((item) => {
                  return (
                    <div
                      key={item}
                      className={classNames(style.body, {
                        'text-secondary':
                          item === 'undefined' || item === 'Non défini',
                      })}
                    >
                      <p className='m-0 fs-lg'>
                        {t(item)}{' '}
                        {data[category]['tooBigGapVariables'].includes(
                          item,
                        ) && (
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='position-fixed'>
                                {t(`component.rhStatsModal.tooltip.gap`)}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className='text-warning'
                            />
                          </OverlayTrigger>
                        )}
                        {(item === 'undefined' || item === 'Non défini') && (
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='position-fixed'>
                                {t(`component.rhStatsModal.tooltip.undefined`)}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className='text-red'
                            />
                          </OverlayTrigger>
                        )}
                      </p>

                      <p className='m-0 fs-lg text-end'>
                        {data[category]['values'][item].effectif}{' '}
                        {data[category]['values'][item].quality !==
                          'success' && (
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='position-fixed'>
                                {t(
                                  `component.rhStatsModal.tooltip.effectif.${data[category]['values'][item].quality}`,
                                )}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className={`text-${data[category]['values'][item].quality}`}
                            />
                          </OverlayTrigger>
                        )}
                      </p>

                      <p className='m-0 fs-lg'>
                        {data[category]['values'][item].percent} %
                      </p>
                    </div>
                  )
                })}
            </div>
          </div>
        ))}
      </Scrollbar>
    </>
  )
}
