import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// Components
import CopyButton from '../../../../components/buttons/CopyButton/CopyButton'
import Date from '../../../../components/render/Date'
import { Modal } from 'react-bootstrap'
// Icons
import { ReactComponent as OkGreen } from '../../../../assets/icons/icon-ok-green.svg'
import { ReactComponent as OkGray } from '../../../../assets/icons/icon-ok-gray.svg'
import { ReactComponent as Error } from '../../../../assets/icons/icon-error.svg'

function ImportReportModal({ modalOpen, closeModal, data }) {
  const { t } = useTranslation()

  return (
    <Modal size='lg' centered show={modalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {
            <div>
              {t('component.importReportModal.title')} <Date>{data?.date}</Date>
            </div>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImportReport data={data} />
      </Modal.Body>
    </Modal>
  )
}

export function ImportReport({ data }) {
  const { t } = useTranslation()
  return (
    <>
      {data && (
        <Container>
          <Column>
            <Item>
              {data.created ? <OkGreen /> : <OkGray />}
              {t('component.importReportModal.item1')} {data.created}
            </Item>
            <Item>
              {data.missingMatricule ? <Error /> : <OkGreen />}
              {t('component.importReportModal.item2')} {data.missingMatricule}
            </Item>

            <Item>
              {data.duplicateEmail.length ? <Error /> : <OkGreen />}
              {t('component.importReportModal.item3')}{' '}
              {data.duplicateEmail.length}
              {!!data.duplicateEmail.length && (
                <CopyButton value={data.duplicateEmail.join('\n')} />
              )}
            </Item>
          </Column>
          <Column>
            <Item>
              {data.updated ? <OkGreen /> : <OkGray />}
              {t('component.importReportModal.item4')} {data.updated}
            </Item>
            <Item>
              {data.missingEmail ? <Error /> : <OkGreen />}
              {t('component.importReportModal.item5')} {data.missingEmail}
            </Item>
            <Item>
              {data.invalidEmail.length ? <Error /> : <OkGreen />}
              {t('component.importReportModal.item6')}{' '}
              {data.invalidEmail.length}
              {!!data.invalidEmail.length && (
                <CopyButton value={data.invalidEmail.join('\n')} />
              )}
            </Item>
          </Column>
        </Container>
      )}
    </>
  )
}

function Container({ children }) {
  return <div className='d-flex justify-content-around'>{children}</div>
}

function Column({ children }) {
  return <div className='d-flex flex-column gap-3'>{children}</div>
}

function Item({ children }) {
  return <div className='d-flex gap-3 align-items-center'>{children}</div>
}

export default ImportReportModal

ImportReportModal.propTypes = {
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  goNextStep: PropTypes.func,
  data: PropTypes.object,
}
