import RespondentForm from './respondentForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './respondentModal.scss'
import { Modal } from 'react-bootstrap'

export function RespondentEditModal({
  respondent,
  onCreate,
  onUpdate,
  show,
  onHide,
  categories,
}) {
  const { t } = useTranslation()
  const edit = !!respondent?.matricule
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      dialogClassName='respondentModal'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {edit ? t('rh.update_rh_info') : t('rh.add_respondent')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RespondentForm
          key={respondent?._id || 'none'}
          respondent={respondent}
          edit={edit}
          onCreate={onCreate}
          onUpdate={onUpdate}
          categories={categories}
          submitText={edit ? t('common.confirm') : t('common.add')}
        />
      </Modal.Body>
    </Modal>
  )
}
