import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ListSettings from './listSettings'

export default function ImportSetting({ setting, confirm, onConfirm }) {
  const { t } = useTranslation()

  return (
    <div>
      {!setting || !setting.variables.length ? (
        <p>{t('component.importSetting.noSetting')}</p>
      ) : (
        <>
          <h4>{t('component.importSetting.heading')}</h4>
          <p className='pb-3 text-black-50'>
            {t('component.importSetting.text')}
          </p>

          <ListSettings setting={setting} />
        </>
      )}

      <Form.Check
        id='confirm'
        name='confirm'
        className='mb-0 mt-5 fs-lg fw-semibold'
        checked={confirm}
        onChange={onConfirm}
        label={t('component.importSetting.confirm')}
      />
    </div>
  )
}
