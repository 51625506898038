import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { EmailTypes } from './EmailTypes'
import { useTranslation } from 'react-i18next'

export default function RespondentIndividualProcess({
  selectedRespondent,
  canAccessSuivi,
  onAccesQuestionnaire,
  onCreateMailInterviewer,
  onCreateEmail,
}) {
  const { t } = useTranslation()
  const hasSelection = !!selectedRespondent.length
  return (
    <Card className='p-4 mb-3'>
      <h4>{t('rh.respondentIndividualProcess.title')}</h4>
      <p className='text-width fs'>
        {t('rh.respondentIndividualProcess.description')}
      </p>
      <Row className='d-flex justify-content-between gap-3'>
        <Col xxl={2}>
          <Card className='p-3 h-100 d-flex flex-column'>
            <h5>{t('rh.respondentIndividualProcess.process1.title')}</h5>
            <p className='text-width fs flex-1'>
              {t('rh.respondentIndividualProcess.process1.description')}
            </p>
            <Button
              disabled={selectedRespondent.length !== 1 || canAccessSuivi}
              variant='dark'
              style={{ width: 'fit-content' }}
              onClick={() =>
                onAccesQuestionnaire(selectedRespondent[0].original)
              }
            >
              {t('rh.respondentIndividualProcess.process1.cta')}
            </Button>
          </Card>
        </Col>
        <Col xxl={3}>
          <Card className='p-3 h-100 d-flex flex-column'>
            <h5>{t('rh.respondentIndividualProcess.process2.title')}</h5>

            <p className='text-width fs flex-1'>
              {t('rh.respondentIndividualProcess.process2.description')}
            </p>
            <Button
              variant='dark'
              style={{ width: 'fit-content' }}
              disabled={!hasSelection}
              onClick={onCreateMailInterviewer}
            >
              {t('rh.respondentIndividualProcess.process2.cta')}
            </Button>
          </Card>
        </Col>
        <Col>
          <Card className='p-3 h-100 d-flex flex-column'>
            <h5>{t('rh.respondentIndividualProcess.process3.title')}</h5>
            <p className='text-width fs flex-1'>
              {t('rh.respondentIndividualProcess.process3.description')}
            </p>
            <div className='d-flex gap-3'>
              <Button
                disabled={!hasSelection}
                variant='dark'
                onClick={() =>
                  onCreateEmail(
                    selectedRespondent,
                    EmailTypes.ENQUETE_INDIVIDUELLE_LANCEMENT,
                  )
                }
              >
                {t('rh.respondentIndividualProcess.process3.cta1')}
              </Button>
              <Button
                variant='dark'
                disabled={!hasSelection}
                onClick={() =>
                  onCreateEmail(
                    selectedRespondent,
                    EmailTypes.ENQUETE_INDIVIDUELLE_RELANCE,
                  )
                }
              >
                {t('rh.respondentIndividualProcess.process3.cta2')}
              </Button>
              <Button
                variant='dark'
                disabled={!hasSelection}
                onClick={() =>
                  onCreateEmail(
                    selectedRespondent,
                    EmailTypes.ENQUETE_INDIVIDUELLE_CLOTURE,
                  )
                }
              >
                {t('rh.respondentIndividualProcess.process3.cta3')}
              </Button>
              <div className='ps-3 border-start'>
                <Button
                  variant='dark'
                  disabled={!hasSelection}
                  onClick={() =>
                    onCreateEmail(
                      selectedRespondent,
                      EmailTypes.ENQUETE_INDIVIDUELLE_ANSWERS,
                    )
                  }
                >
                  {t('rh.respondentIndividualProcess.process3.cta4')}
                </Button>
              </div>
            </div>
          </Card>
        </Col>
        <Col xxl={2}>
          <Card className='p-3 h-100 d-flex flex-column'>
            <h5>{t('rh.respondentIndividualProcess.process4.title')}</h5>
            <p className='text-width fs flex-1'>
              {t('rh.respondentIndividualProcess.process4.description')}
            </p>
            <div className='d-flex gap-3'>
              <Button
                disabled={!canAccessSuivi}
                variant='dark'
                style={{ width: 'fit-content' }}
                onClick={() =>
                  onAccesQuestionnaire(selectedRespondent[0].original)
                }
              >
                {t('rh.respondentIndividualProcess.process4.cta')}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}
